import { FC, useState, MouseEvent as ReactMouseEvent, useEffect } from 'react'
import { Props } from './types'

export const Checkbox: FC<Props> = ({ label, id, checked, onClick }) => {
  const [isChecked, setIsChecked] = useState(checked)

  const handleChange = () => {
    setIsChecked(!isChecked)
  }
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <div
      onClick={(e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onClick) {
          onClick(e)
        }
        handleChange()
      }}
    >
      <label
        htmlFor={id}
        className="ml-2 text-sm font-medium text-gray-900 flex items-center gap-2.5"
      >
        <input
          id={id}
          type="checkbox"
          checked={isChecked}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
          onChange={handleChange}
        />
        {label}
      </label>
    </div>
  )
}
