/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStore } from '~/app/store'
import { UserEdit } from '~/entities/users/user-edit'
import { formatErrorForToast, usePageTitle, useToast } from '~/shared/lib'
import { ArrowLeftSVG, Button, IconSave, PageTitle } from '~/shared/ui'
import Spinner from '~/shared/ui/spinner'

export const UserEditFeature = () => {
  const { t } = useTranslation()
  const { usersStore } = useStore()
  const { loaders } = usersStore
  const toast = useToast()
  const navigate = useNavigate()

  const { updateUser } = usersStore
  usePageTitle({
    title: 'Редактировать профиль',
  })
  return (
    <div>
      <div className="flex justify-between items-center pb-6">
        <div
          className="flex items-center w-full md:w-auto max-w-md cursor-pointer"
          onClick={() => navigate('/settings')}
        >
          <div className="pr-4">
            <ArrowLeftSVG color="#707070" size="30" />
          </div>

          <PageTitle
            title="Редактировать профиль"
            size="text-[24px]"
            color="text-[#242424]"
          />
        </div>

        <Button
          intent="success"
          onClick={async () => {
            try {
              await updateUser()
              toast.open('Профиль успешно отредактирован!', 'success')
            } catch (error: any) {
              toast.open(formatErrorForToast(error), 'danger')
            }
          }}
        >
          {loaders.userEdit ? <Spinner /> : <IconSave />}

          {t('save')}
        </Button>
      </div>
      <UserEdit />
    </div>
  )
}
