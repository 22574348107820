import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import { ListUser, Roles, Settings, UserById } from './types'

export class UsersService {
  static getUsers(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ListUser[]>> {
    return apiService.get<AxiosResponse<ListUser[]>>('/users', config)
  }

  static getRoles(): Promise<AxiosResponse<Roles[]>> {
    return apiService.get<AxiosResponse<Roles[]>>('/userRoles')
  }

  static getPermission(): Promise<AxiosResponse> {
    return apiService.get<AxiosResponse>('/userRoles/permissions')
  }

  static getUsersById(id: string): Promise<AxiosResponse<UserById>> {
    return apiService.get<AxiosResponse<UserById>>(`/users/${id}`)
  }

  static getRoleById(id: string): Promise<AxiosResponse<Roles>> {
    return apiService.get<AxiosResponse<Roles>>(`/userRoles/${id}`)
  }

  static loadMoreUsers(
    params: AxiosRequestConfig,
  ): Promise<AxiosResponse<ListUser[]>> {
    return apiService.get<AxiosResponse<ListUser[]>>('/users', params)
  }

  static updateUser(
    id: string,
    data?: {
      name: string
      settings?: Settings
      password?: string
      newPassword?: string
      roles?: Roles[]
    },
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/users/${id}/edit`, {
      ...data,
      id,
      roles: data?.roles?.map((el) => ({
        name: el.name,
        id: el.id,
      })),
    })
  }

  static updateRole(
    id: string,
    data: {
      name: string
      permissions: string[]
    },
  ): Promise<AxiosResponse> {
    return apiService.put<AxiosResponse>(`/userRoles/${id}`, data)
  }

  static createRole(data: { name: string; permissions: string[] }) {
    return apiService.post(`/userRoles`, {
      ...data,
    })
  }

  static blockUser(userId: string, data: { enabled: boolean }) {
    return apiService.post(`/users/${userId}/block`, {
      ...data,
    })
  }

  static editPasswordProfile(
    userId: string,
    data: { password: string; new_password: string },
  ) {
    return apiService.post(`/users/${userId}/change-password`, {
      ...data,
    })
  }

  static createUser(data: {
    name: string
    email: string
    password: string
    password_confirmation: string
    settings: string[]
    roles: Roles[]
    legal_entity_id: string | null
  }) {
    return apiService.post(`/users`, {
      ...data,
    })
  }

  static deleteRole(id: string) {
    return apiService.delete(`/userRoles/${id}`)
  }

  static deleteUser(id: string) {
    return apiService.delete(`/users/${id}`)
  }
}
