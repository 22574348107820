/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from 'mobx-react-lite'
import { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'usehooks-ts'
import { useStore } from '~/app/store'
import { GlobalService } from '~/shared/api/services/global'
import { useToast } from '~/shared/lib'
import {
  CustomSelect,
  DropFileZone,
  ImagePreview,
  SelectOption,
  Switcher,
  ToolTip,
} from '~/shared/ui'
import { Input } from '~/shared/ui/input'

export const CreateCategoryFormAttachments = observer(() => {
  const { t } = useTranslation()
  const { categoriesStore } = useStore()
  const toast = useToast()

  const [parentCategory, setParentCategory] = useState<SelectOption>()

  const dropFileZoneRefIcon: RefObject<HTMLInputElement> = useRef(null)
  const dropZoneRefBanner: RefObject<HTMLInputElement> = useRef(null)

  const handleZoneClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ref: React.RefObject<HTMLInputElement>,
  ) => {
    const target = e.target as HTMLDivElement
    if (ref.current && target.tagName === 'DIV') {
      ref.current.click()
    }
  }

  const {
    setCreateCategoryData,
    createCategoryData,
    getCategories,
    getMappedData,
    categoriesLoading,
    createCategoryDataValidation,
    setIsErrorSlug,
  } = categoriesStore

  const handleBlur = () => {
    if (createCategoryDataValidation.isErrorSlug) {
      setIsErrorSlug(false)
    }
  }

  useEffect(() => {
    if (createCategoryData.category.storeId) {
      getCategories(`stores=${createCategoryData.category.storeId}`, true)
    }
  }, [createCategoryData.category.storeId, getCategories])

  useUpdateEffect(() => {
    if (parentCategory?.value) {
      setCreateCategoryData(
        { parentCategoryId: parentCategory.value },
        'category',
      )
    }
  }, [parentCategory])

  return (
    <>
      <div className="flex items-center justify-between flex-wrap gap-3">
        <div className="flex items-center gap-3 flex-wrap">
          <h2 className="text-lg font-medium w-[200px] text-title-color">
            {t('generalSettings')}
          </h2>

          <Input
            id="slug"
            name="slug"
            label={`${t('uniqueCustom')}...`}
            labelType="floating"
            onBlur={handleBlur}
            value={createCategoryData.category.slug}
            className="max-w-[320px]"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCreateCategoryData(
                { [e.target.name]: e.target.value },
                'category',
              )
            }
            status={createCategoryDataValidation.isErrorSlug ? 'error' : null}
            helperText={
              createCategoryDataValidation.isErrorSlug ? t('requiredField') : ''
            }
          />
        </div>

        <div className="flex items-center">
          <Switcher
            labelClassNames="font-medium text-[16px] text-title-color"
            defaultValue={createCategoryData.category.showInMenu}
            label={t('showCategory')}
            onChange={(value) =>
              setCreateCategoryData({ showInMenu: value }, 'category')
            }
          />
          <ToolTip
            iconSize={20}
            tooltipTitle={t('defaultToolTipTitle')}
            tooltipText={t('defaultToolTipText')}
          />
        </div>

        <div className="w-full">
          <CustomSelect
            placeholder="Родительская категория"
            value={parentCategory as SelectOption}
            isLoading={categoriesLoading}
            options={getMappedData.categories.filter(
              (el) => el.value !== createCategoryData.category.id,
            )}
            onChange={(e) => setParentCategory(e as SelectOption)}
          />
        </div>
      </div>

      <hr className="my-3" />

      <div className="flex flex-col gap-3 cursor-pointer ">
        <div
          className="flex items-center gap-3"
          onClick={(e) => handleZoneClick(e, dropZoneRefBanner)}
        >
          <h2 className="text-lg font-medium text-title-color">
            {t('categoryBanner')}
          </h2>
          <ToolTip
            tooltipTitle={t('defaultToolTipTitle')}
            tooltipText={t('defaultToolTipText')}
          />
        </div>

        {createCategoryData.category?.images?.original ? (
          <ImagePreview
            src={createCategoryData.category?.images?.original}
            alt="bg-preview"
            onRemove={() => {
              categoriesStore.removeCategoryImage('original')
            }}
          />
        ) : (
          <DropFileZone
            dropZoneRefBanner={dropZoneRefBanner}
            onChange={async (files) => {
              try {
                const formData = new FormData()
                formData.append('file', files[0])
                const resp = await GlobalService.uploadImage(formData)
                categoriesStore.setCategoryImage(
                  'original',
                  resp.original || '',
                )

                categoriesStore.setAttachmentId(resp?.id)
              } catch (error: any) {
                toast.open(error.toString(), 'danger')
              }
            }}
          />
        )}
      </div>
      <hr className="my-3 mt-4" />

      <div className="flex flex-col gap-3 h-full w-full cursor-pointer ">
        <div
          className="flex items-center gap-3 "
          onClick={(e) => handleZoneClick(e, dropFileZoneRefIcon)}
        >
          <h2 className="text-lg font-medium text-title-color">{t('icon')}</h2>
          <ToolTip
            tooltipTitle={t('defaultToolTipTitle')}
            tooltipText={t('defaultToolTipText')}
          />
        </div>

        {createCategoryData.category?.images?.icon ? (
          <ImagePreview
            src={createCategoryData.category?.images?.icon}
            alt="icon-preview"
            onRemove={() => {
              categoriesStore.removeCategoryImage('icon')
            }}
          />
        ) : (
          <DropFileZone
            className="h-full "
            dropFileZoneRefIcon={dropFileZoneRefIcon}
            onChange={async (files) => {
              try {
                const formData = new FormData()
                formData.append('file', files[0])
                const resp = await GlobalService.uploadImage(formData)
                categoriesStore.setCategoryImage(
                  'icon',
                  resp.original as string,
                )
                categoriesStore.setIconAttachmentId(resp.id)
              } catch (error: any) {
                toast.open(error.toString(), 'danger')
              }
            }}
          />
        )}
      </div>
    </>
  )
})
