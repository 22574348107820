/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from '~/app/store'
import { Button, Card, DeleteSVG } from '~/shared/ui'
import { Input } from '~/shared/ui/input'
import { UserRole } from '~/features/roles'
import { UnlockSVG } from '~/shared/ui/SVG/unlockSVG'
import Spinner from '~/shared/ui/spinner'
import { ModalDeleteUser } from '~/features/user-edit/modal-delete'
import { BlocketSVG } from '~/shared/ui/SVG/blockedSVG'
import { formatErrorForToast, useToast } from '~/shared/lib'

export const UserEdit = observer(() => {
  const { t } = useTranslation()
  const { usersStore } = useStore()
  const { id } = useParams()
  const [openModal, setOpenModal] = useState(false)
  const toast = useToast()

  const { editedUser, loaders, validationErrors, getUsersById, setEditUser } =
    usersStore
  const [isShowPassword, setIsShowPassword] = useState({
    password: false,
    newPassword: false,
  })
  useEffect(() => {
    if (id) {
      getUsersById(id)
    }
  }, [getUsersById, id])

  const handleChange =
    (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditUser(name, e.target.value)
    }

  const togglePasswordVisibility = (type: keyof typeof isShowPassword) => {
    setIsShowPassword((prev) => ({
      ...prev,
      [type]: !prev[type],
    }))
  }

  return (
    <form>
      <div className="flex gap-4">
        <Card className="flex w-[400px] h-full flex-col gap-4">
          <p className="text-[20px] text-[#242424]">Информация профиля</p>

          <Input
            className="rounded"
            label={t('name')}
            value={editedUser?.name || ''}
            onChange={handleChange('name')}
            labelType="floating"
            helperText={validationErrors['user.name']}
            status={validationErrors['user.name'] ? 'error' : undefined}
          />
          <Input
            className="rounded"
            label={t('email')}
            type="email"
            intent="disabled"
            value={editedUser?.email || ''}
            id="email"
            readOnly
            autoComplete="new-email"
            labelType="floating"
          />

          <p className="text-[20px] text-[#242424]">{t('password')}</p>
          <Input
            name="password"
            label={t('password')}
            type={isShowPassword.password ? 'text' : 'password'}
            onChange={handleChange('password')}
            id="password"
            labelType="floating"
            helperText={validationErrors.password}
            status={validationErrors.password ? 'error' : undefined}
            showPassword={() => togglePasswordVisibility('password')}
            autoComplete="new-password"
          />
          <Input
            name="newPassword"
            label="Пароль еще раз"
            type={isShowPassword.newPassword ? 'text' : 'password'}
            onChange={handleChange('passwordRepeat')}
            id="newPassowrd"
            labelType="floating"
            helperText={validationErrors.passwordRepeat}
            status={validationErrors.passwordRepeat ? 'error' : undefined}
            showPassword={() => togglePasswordVisibility('newPassword')}
            autoComplete="new-password"
          />
          <div>
            <div className=" pt-4">
              {editedUser?.enabled ? (
                <div className="flex justify-center">
                  {' '}
                  <Button
                    intent="danger"
                    className="w-[190px]"
                    onClick={async () => {
                      try {
                        await usersStore.blockUser(editedUser.id, false)
                        toast.open('Профиль успешно заблокирован!', 'success')
                      } catch (error: any) {
                        toast.open(formatErrorForToast(error), 'danger')
                      }
                    }}
                  >
                    <BlocketSVG color="white" />
                    Заблокировать
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Button
                    intent="yellow"
                    className="w-[190px]"
                    onClick={async () => {
                      try {
                        await usersStore.blockUser(editedUser.id, true)
                        toast.open('Профиль успешно разблокирован!', 'success')
                      } catch (error: any) {
                        toast.open(formatErrorForToast(error), 'danger')
                      }
                    }}
                  >
                    <UnlockSVG color="white" /> Разблокировать
                  </Button>
                  <Button
                    intent="danger"
                    className="w-[150px]"
                    onClick={() => setOpenModal(true)}
                  >
                    {loaders.userDelete ? (
                      <Spinner />
                    ) : (
                      <DeleteSVG color="white" />
                    )}
                    Удалить
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Card>
        <UserRole />
      </div>
      <ModalDeleteUser
        isOpen={openModal}
        id={editedUser?.id}
        onClose={(e) => {
          e.preventDefault()
          setOpenModal(false)
        }}
      />
    </form>
  )
})
