import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '~/app/store'
import { Card, CustomSelect } from '~/shared/ui'
import { generateUEID } from '~/shared/lib/helper-functions/ueid'

export const UserRole = observer(() => {
  const { usersStore } = useStore()
  const { getRoles, roles, getPermission, editedUser, setEditUser } = usersStore

  useEffect(() => {
    getRoles()
    getPermission()
  }, [getRoles, getPermission])

  return (
    <div className="w-300px">
      <Card className="flex w-[400px] h-full flex-col gap-4">
        <p className="text-[20px] text-[#242424]"> Роли </p>

        <CustomSelect
          key={generateUEID()}
          isMulti
          placeholder="Роли"
          options={roles.map((el) => ({
            label: el.name || el.name,
            value: el.id,
            ...el,
          }))}
          value={editedUser?.roles.map((e) => ({
            label: e.name,
            value: e.id,
            ...e,
          }))}
          onChange={(value) => {
            setEditUser('roles', value as string)
          }}
        />
      </Card>

      <hr />
    </div>
  )
})
